<template>
  <page-view title="查看发票" left-arrow white-bg>
     <iframe
      width="100%"
      height="100%"
      scrolling="no"
      frameborder="0"
      :src="`/pdf/web/viewer.html?file=${pdfurl}`"
    ></iframe>
  </page-view>
</template>

<script>
// let PDFJS = require('pdfjs-dist/es5/build/pdf.js');
export default {
  name: 'PdfViewer',
  data() {
    return {
      pdfurl: null
    }
  },
  methods: {
    get_pdfurl(){
      let {pdfurl} = this.$route.query;
      this.pdfurl = pdfurl
    },
  },
  mounted() {
    this.get_pdfurl();
  },
}
</script>